@import 'src/base';

.validate-user {
    margin-top: -10px;
    width: 100%;
    display: inline-block;
}

.title-input{
    position: relative;
    text-align: start;
    width: 50%;
    margin-right: auto;
    margin-top: 0px;
    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}

.label-email{
    margin-bottom: 0px;
    margin-left: 2px;
}

.input-email{
    // box-sizing: border-box;
    position: relative;
    background: #FAFAFA;
    border: 1px solid rgba(0, 0, 0, 0.25098);
    border-radius: 4px;
}

.captcha-user{
    padding: 6px;
    margin-top: 40px;
    text-align: center;
}

.btn-validate{
text-align: center;
position: relative;
width: 100px;
height: 30px;
// left: 80%;
float: right;
background: #553BBD;
border: 1px solid rgba(0, 0, 0, 0.15);
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.101961);
border-radius: 6px;
}

.btn-validate:focus{
    text-align: center;
position: relative;
width: 100px;
height: 30px;
// left: 80%;
float: right;
background: #553BBD;
border: 1px solid rgba(0, 0, 0, 0.15);
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.101961);
border-radius: 6px;
    }
.btn-validate:active{
    transform: scale(1.1);
    transition: 0.1s;
}

.alert-success{

}

.alert-error{

}

.msj-success{
    display: block;
    position: relative;
    width: 100%;
    height: 20px;
    top: -20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #5CCC9F;
}

.title-alert{
    display: block;
    position: relative;
    width: 100%;
    height: 16px;
    top: -20px;
    text-align: start;
    margin-left: auto;
    margin-right: auto;

    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: black;
}

.msj-success-body{
    width: 100%;
    padding: 36px;
    position: absolute;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 160px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.msj-error-body{
    width: 100%;
    padding: 36px;
    position: absolute;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 160px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.msj-error{
    color: #CC5C5C;
    display: block;
    position: relative;
    width: 100%;
    height: 20px;
    top: -20px;
    margin-bottom: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
}

.btn-reingresar{
    text-align: center;
    position: relative;
    color: #FFFFFF;
    width: 100px;
    height: 30px;
    top: 140px;
    margin-bottom: 50%;
    background: #553BBD;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.101961);
    border-radius: 6px;
}

.msj-register{
    display: block;
    margin-top: 10px;
    font-size:larger;
}

.container-question-help{
    position: relative;
    width: 100%;
    text-align:center;
    margin:0px auto;
    margin-top: 480px;
  }
  