@import 'src/base';

.msj-success{
    font-weight: bold;
}

.msj-error{
    font-weight: bold;
}

.title-alert{
    font-weight: bold;
}

.alert-success{
    margin-bottom: 2rem;
}