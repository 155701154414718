@import 'src/base';

.support-control-panel {
    background: $background-content;
    margin: 2rem;
    .support-control-panel-expand{
        margin-left: 1rem;
    }
    .support-control-panel-tree {
        background: $background-content;
        
        .support-control-panel-tree-title {
            .support-control-panel-tree-button {
                opacity: 0;
            }
        }
        .support-control-panel-tree-title:hover {
            .support-control-panel-tree-button {
                opacity: 100;
            }
        }
    }
}
