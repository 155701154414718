@import 'src/base';

.alert {
  
}

.response-image{
    width: 80px;
    height: 80px;
    position:relative;
    display: block;
    bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.title-response-success{
    // font-weight: bold;
}