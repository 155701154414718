@import 'src/base';

.type-1-support {
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: -20px; 
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.type1-items{
    text-align: left;
    position: relative;
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: -20px; 
}

.title-suggestions{
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 24px;
    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.8);
}

.btnAccefy{
    text-align: end;
    // display: flex;
    margin-top: 10px;
    color: #FFF;
    background: rgba(92, 47, 156, 1);
}