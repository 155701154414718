@import 'src/base';

.background {
    height: 950px;
    width: 100%;
    display: block;
    position: relative;
    background: #f5f5f5;
    overflow-x: hidden;
}

.main-support {
    margin-top: 90px;
    margin-left: auto;
    margin-right: auto;
    width: 92%;
}

.list-questions-items {
    padding-left: 50px;
    text-align: start;
    width: 100%;
    height: 72px;
    border-radius: 10px;
    margin-top: -20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.list-questions-items:hover {
    transform: scale(1.01);
    transition: 0.1s;
}
.list-questions-items:focus {
    border-color: transparent;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: gray;
    display: inline-block;
}

.icon-right-outlined {
    float: right;
    right: 0;
    padding-right: 8px;
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.25);
}
.icon-right-outlined:focus {
    position: absolute;
    text-align: right;
    right: 0;
    padding-right: 8px;
    top: 40%;
    color: rgba(0, 0, 0, 0.25);
}

.title {
    text-align: start;
    position: absolute;
    width: 100%;
    height: 20px;
    padding-left: 10px;
    top: 4%;
    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 550;
    font-size: 16px;
    line-height: 20px;
    color: #553bbd;
}

.subtitle {
    text-align: start;
    position: absolute;
    width: 100%;
    height: 20px;
    left: 28px;
    top: 7%;

    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #553bbd;
}

.btnBack {
    position: absolute;
    margin-left: 20px;
    margin-top: 0px;
    color: rgba(92, 47, 156, 1);
    background-color: transparent;
    border-color: transparent;
    padding: 0px;
    padding-right: 4px;
}
.btnBack:hover {
    color: rgb(41, 4, 93);
    background-color: transparent;
    border-color: transparent;
    padding: 0px;
    padding-right: 4px;
}
.btnBack:focus {
    border-color: transparent;
    background: #f5f5f5;
    color: rgba(92, 47, 156, 1);
}

@media only screen and (min-width: 1000px) {
    .main-support {
        // margin:80px;
        // width: 100%;
        // text-size-adjust: 20px;
        width: 60%;
    }
}
