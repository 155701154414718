@import 'src/base';

.new-ticket {
    width: 100%;
    display: inline-block;
}

.textArea {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25098);
    border-radius: 4px;
}

// .labelTextArea{
//   padding: 4px;
//   height: 100%;
//   width: 100%;
//   margin: auto;
// }

// .labelCapture{
//   margin-left: 30px;
// }

.formTicket {
    height: 100%;
    width: 100%;
    margin-top: 4%;
}

.background-image {
    width: 100%;
    height: 100%;
    top: -90px;
    position: relative;
    z-index: 0;
}

.title-ticket-input {
    position: relative;
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -80px;
    margin-bottom: 30px;
    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
}

.container-question-help {
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0px auto;
    margin-top: 0px;
}

.question-help {
    // text-align: center;
    margin-top: 105px;
    // position: absolute;
}

.msj-question-help {
    display: block;
    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #919191;
}

.buttons-help {
    display: inline;
}

.btn-help {
    text-align: center;
    width: 50px;
    height: 40px;
    margin: 8px;
    color: #919191;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
}
.div-btn {
    display: block;
}

.btn-send {
    text-align: center;
    position: relative;
    line-height: 2px;
    width: 100px;
    height: 30px;
    float: right;
    // left: 80%;
    background: #553bbd;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.101961);
    border-radius: 6px;
    z-index: 2;
}

.response-success {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.response-success-label {
    position: relative;
    line-height: -10px;
    width: 100%;
}

.response-image {
    width: 80px;
    height: 80px;
    position: relative;
    display: block;
    bottom: 10px;
}

.title-response-success {
    position: relative;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #15b9b0;
    margin-left: auto;
    margin-right: auto;
}

.title-response-error {
    position: relative;
    width: 100%;
    height: 100%;
    // top: -40px;
    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: red;
    margin-left: auto;
    margin-right: auto;
}

.body-response-success {
    display: block;
    margin-left: auto;
    margin-right: auto;
    // position: relative;
    width: 90%;
    // // height: 82px;
    // // left: 28px;
    top: -80px;
    // font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    // font-size: 12px;
    line-height: 25px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45098);
}

.btn-back-response {
    display: block;
    width: 100px;
    height: 48px;
    text-align: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.101961);
    border-radius: 6px;
}

@media only screen and (max-width: 890px) {
    .labelTextArea {
        display: flex;
        margin-bottom: 10px;
    }
    .textArea {
        margin-top: 10px;
    }
}
